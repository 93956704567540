






import { Component, Prop, Vue } from 'vue-property-decorator'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
@Component({
  components: {
    'v-chart': ECharts,
  },
})
export default class BarChart extends Vue {
  @Prop(String) yTitle!: string
  @Prop(String) xTitle!: string
  @Prop(Number) xTitleGap!: number
  @Prop() yData!: any
  @Prop() xData!: any

  get option() {
    return {
      title: {
        // text: 'Impressions Over Time',
        // subtext: 'Impressions Over Time',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '8%',
        bottom: '8%',
        containLabel: true,
      },
      animationDuration: 3000,
      xAxis: {
        type: 'category',
        name: this.xTitle.toLowerCase(),
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: 15,
        },
        nameGap: this.xTitleGap,
        axisLabel: {
          show: true,
          interval: 0,
          margin: 8,
          fontSize: 12,
          rotate: this.xData.length > 20 ? 45 : 0,
        },
        show: true,
        data: this.xData,
      },
      yAxis: {
        type: 'value',
        name: this.yTitle,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: 15,
          lineHeight: 50,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ['#616161'],
          },
        },
      },
      textStyle: {
        color: 'silver',
        width: 50,
      },
      series: [
        {
          data: this.yData,
          type: 'bar',
        },
      ],
    }
  }
}
