var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-100 h-100 flex items-center justify-center overflow-hidden relative",
      },
      [
        _c("div", { staticClass: "absolute flex z-999 loading-positon" }, [
          _c("div", { staticClass: "loader loader-inline mr2 z-999" }),
          _c("div", [_vm._v("Fetching...")]),
        ]),
        _c(
          "div",
          {
            staticClass: "absolute w-100 flex items-center justify-center pa4",
          },
          [
            _c("img", {
              staticClass: "w-60 center",
              attrs: { src: require("@/assets/analytics/analytics.svg") },
            }),
            _c("img", {
              staticClass: "w-40 center",
              attrs: { src: require("@/assets/analytics/pie.svg") },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }