






import { Component, Prop, Vue } from 'vue-property-decorator'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/title'

@Component({
  components: {
    'v-chart': ECharts,
  },
})
export default class PieChart extends Vue {
  @Prop({ default: () => [] })
  seriesData!: any
  @Prop({ default: () => [] })
  xAxisData!: any
  @Prop({ default: () => [] })
  selected!: any
  @Prop({ default: 'value' })
  scaleType!: any
  @Prop({ default: '' })
  chartName!: any
  @Prop({ default: () => [] })
  chartMethods!: any

  capitalizeFirstLetter(string: string) {
    string = string.replace(/_/g, ' ')

    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  get fontColor() {
    return '#D3D3D3'
  }
  get formatedSeriesData() {
    return this.seriesData
  }

  get chartOptions() {
    return {
      title: {
        // text: this.capitalizeFirstLetter(this.chartName),
        // subtext: '纯属虚构',
        left: 'center',
        top: 15,
        textStyle: {
          color: '#fff',
        },
      },
      tooltip: {
        trigger: 'item',
        backgroundColor: '#212121',
        // formatter: '{a} <br/>{b} : {c} ({d}%''
        formatter: (params: any) => {
          return `
              ${this.capitalizeFirstLetter(params.name)} : ${parseInt(params.value).toLocaleString('en-US')} 
              (${params.percent}%)`
        },
      },
      textStyle: {
        color: this.fontColor,
        width: 50,
      },
      color: [
        '#D91F22',
        '#E67E22',
        '#D35400',
        '#CB699B',
        '#2292CB',
        '#7F438A',
        '#EE7040',
        '#C0392B',
        '#2980B9',
        '#1ABC9C',
        '#27AE60',
        '#D4AC0D',
        '#831620',
        '#7B85FC',
      ],
      series: {
        radius: '50%',
        label: {
          alignTo: 'center',
          formatter: (params: any) => {
            return ` ${this.capitalizeFirstLetter(params.name)} (${parseInt(params.percent)}%)`
          },
          fontWeight: 500,
        },
        name: this.chartName,
        type: 'pie',
        data: this.seriesData,
      },
    }
  }
}
