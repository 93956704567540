var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "absolute ml6" },
            [_c("TheTitle", { attrs: { goBack: "" } })],
            1
          ),
          _c("div", { staticClass: "limiter scrollbar mb5" }, [
            _c(
              "div",
              { staticClass: "flex justify-between items-center w-100 mt4" },
              [
                _c("TheTitle", {
                  attrs: {
                    icon: "bar_chart",
                    title: _vm.campaign.name,
                    large: "",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-adori-light-gray br3 ba b--adori-gray light-gray pv2 flex ph4 flex-column",
                class: { "min-height-1": _vm.loading },
              },
              [
                _vm.loading
                  ? _c("PlaceHolder")
                  : _c("div", [
                      _c("div", { staticClass: "f2 fw1 mt3" }, [
                        _vm._v("Summary"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex justify-between mt3 ph3" },
                        [
                          _c("div", [
                            _c("p", { staticClass: "f4 fw4" }, [
                              _vm._v("Total impressions"),
                            ]),
                            _c("p", { staticClass: "f3" }, [
                              _vm._v(_vm._s(_vm.summary.totalServed)),
                            ]),
                          ]),
                          _c("div", [
                            _c("p", { staticClass: "f4 fw4" }, [
                              _vm._v("Total goal"),
                            ]),
                            _c("p", { staticClass: "f3" }, [
                              _vm._v(_vm._s(_vm.summary.totalGoal)),
                            ]),
                            _c("p", { staticClass: "f6 fw4" }, [
                              _vm._v(
                                _vm._s(_vm.percentageDelivered) + " delivered"
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _c("p", { staticClass: "f4 fw4" }, [
                              _vm._v("Campaign period"),
                            ]),
                            _c("p", { staticClass: "f3" }, [
                              _vm._v(_vm._s(_vm.campaignPeriod.period)),
                            ]),
                            _c("p", { staticClass: "f6 fw4" }, [
                              _vm._v(_vm._s(_vm.campaignPeriod.duration)),
                            ]),
                          ]),
                          _c("div", { staticClass: "w-30" }, [
                            _c("p", { staticClass: "f4 fw4" }, [
                              _vm._v("Select specific date"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("date-picker", {
                                  staticClass: "adori-date-picker w-100 mr2",
                                  attrs: {
                                    lang: "en",
                                    type: "date",
                                    format: "YYYY-MM-DD",
                                    "not-before": _vm.notBeforeStartDate,
                                    "not-after": _vm.notAfterStartDate,
                                    placeholder: "Enter Start Date",
                                  },
                                  on: { input: _vm.validateDate },
                                  model: {
                                    value: _vm.campaignStartDate,
                                    callback: function ($$v) {
                                      _vm.campaignStartDate = $$v
                                    },
                                    expression: "campaignStartDate",
                                  },
                                }),
                                _c("date-picker", {
                                  staticClass: "adori-date-picker w-100",
                                  attrs: {
                                    lang: "en",
                                    type: "date",
                                    format: "YYYY-MM-DD",
                                    "not-before": _vm.notBeforeEndDate,
                                    "not-after": _vm.notAfterEndtDate,
                                    placeholder: "Enter End Date",
                                  },
                                  on: { input: _vm.validateDate },
                                  model: {
                                    value: _vm.campaignEndDate,
                                    callback: function ($$v) {
                                      _vm.campaignEndDate = $$v
                                    },
                                    expression: "campaignEndDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex justify-end mt3" },
              [
                _c("BaseButtonRed", {
                  attrs: {
                    text: "Export Campaign",
                    onClick: _vm.handleExportCampaign,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-adori-light-gray br3 ba b--adori-gray light-gray pv2 flex ph4 flex-column mt3",
                class: { "min-height": _vm.timeLoader },
              },
              [
                _vm.timeLoader
                  ? _c("PlaceHolder")
                  : _c(
                      "div",
                      {
                        class: {
                          "min-height-1":
                            _vm.ImpressionOverTime.yData.length === 0,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-between items-center mt4",
                          },
                          [
                            _c("div", { staticClass: "f2 fw1 mt3" }, [
                              _vm._v("Impression over time"),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.ImpressionOverTime.yData.length > 0,
                                    expression:
                                      "ImpressionOverTime.yData.length > 0",
                                  },
                                ],
                                staticClass: "flex ba br2 pointer",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ph4 pv3 br",
                                    class: {
                                      "black bg-white": _vm.filter === "DAILY",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeFilter("DAILY")
                                      },
                                    },
                                  },
                                  [_vm._v("Daily")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ph4 pv3 br",
                                    class: {
                                      "black bg-white": _vm.filter === "WEEKLY",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeFilter("WEEKLY")
                                      },
                                    },
                                  },
                                  [_vm._v("Weekly")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ph4 pv3 br",
                                    class: {
                                      "black bg-white":
                                        _vm.filter === "MONTHLY",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeFilter("MONTHLY")
                                      },
                                    },
                                  },
                                  [_vm._v("Monthly")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("bar-chart", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ImpressionOverTime.yData.length > 0,
                              expression: "ImpressionOverTime.yData.length > 0",
                            },
                          ],
                          attrs: {
                            xData: _vm.ImpressionOverTime.xData,
                            yData: _vm.ImpressionOverTime.yData,
                            xTitle: _vm.ImpressionOverTime.filter,
                            xTitleGap: _vm.ImpressionOverTime.xGap,
                            yTitle: "Impressions",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.ImpressionOverTime.yData.length === 0,
                                expression:
                                  "ImpressionOverTime.yData.length === 0",
                              },
                            ],
                            staticClass: "flex justify-center mt4 f3",
                          },
                          [_vm._v("No data found")]
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-adori-light-gray br3 ba b--adori-gray light-gray pv2 flex ph4 flex-column mt3",
                class: { "min-height": _vm.positionLoader },
              },
              [
                _vm.positionLoader
                  ? _c("PlaceHolder")
                  : _c(
                      "div",
                      {
                        class: {
                          "min-height-1":
                            _vm.ImpressionOverPosition.length === 0,
                        },
                      },
                      [
                        _c("div", { staticClass: "f2 fw1 mt3" }, [
                          _vm._v("Impression by position"),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ImpressionOverPosition.length > 0,
                                expression: "ImpressionOverPosition.length > 0",
                              },
                            ],
                            staticClass:
                              "flex justify-between items-center ph3",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex w-60" },
                              [
                                _c("PieChart", {
                                  attrs: {
                                    seriesData: _vm.ImpressionOverPosition,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "w-40" }, [
                              _c("table", { staticStyle: { width: "100%" } }, [
                                _c("thead", [
                                  _c("tr", { staticClass: "ba b--red" }, [
                                    _c("th", { staticClass: "f4 fw4 tl" }, [
                                      _vm._v("Position"),
                                    ]),
                                    _c("th", { staticClass: "f4 fw4 tr" }, [
                                      _vm._v("Impression"),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.ImpressionOverPosition,
                                    function (data) {
                                      return _c("tr", { key: data.value }, [
                                        _c("th", { staticClass: "f5 fw1 tl" }, [
                                          _vm._v(_vm._s(data.name)),
                                        ]),
                                        _c("th", { staticClass: "f5 fw1 tr" }, [
                                          _vm._v(_vm._s(data.value)),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ImpressionOverPosition.length === 0,
                                expression:
                                  "ImpressionOverPosition.length === 0",
                              },
                            ],
                            staticClass: "flex justify-center f3 mt5",
                          },
                          [_vm._v("No data found")]
                        ),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-adori-light-gray br3 ba b--adori-gray light-gray pv2 flex ph4 flex-column mt3",
                class: { "min-height": _vm.flightLoader },
              },
              [
                _vm.flightLoader
                  ? _c("PlaceHolder")
                  : _c(
                      "div",
                      {
                        class: {
                          "min-height-1": _vm.ImpressionOverFlight.length === 0,
                        },
                      },
                      [
                        _c("div", { staticClass: "f2 fw1 mt3" }, [
                          _vm._v("Impression by Flight"),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ImpressionOverFlight.length > 0,
                                expression: "ImpressionOverFlight.length > 0",
                              },
                            ],
                            staticClass:
                              "flex justify-between items-center ph3",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex w-60" },
                              [
                                _c("PieChart", {
                                  attrs: {
                                    seriesData: _vm.ImpressionOverFlight,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "w-40 mb4" }, [
                              _c("table", { staticStyle: { width: "100%" } }, [
                                _c("thead", [
                                  _c("tr", { staticClass: "ba b--red" }, [
                                    _c("th", { staticClass: "f4 fw4 tl" }, [
                                      _vm._v("Flight"),
                                    ]),
                                    _c("th", { staticClass: "f4 fw4 tr" }, [
                                      _vm._v("Impression"),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.ImpressionOverFlight,
                                    function (data) {
                                      return _c("tr", { key: data.name }, [
                                        _c("th", { staticClass: "f5 fw1 tl" }, [
                                          _vm._v(_vm._s(data.name)),
                                        ]),
                                        _c("th", { staticClass: "f5 fw1 tr" }, [
                                          _vm._v(_vm._s(data.value)),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ImpressionOverFlight.length === 0,
                                expression: "ImpressionOverFlight.length === 0",
                              },
                            ],
                            staticClass: "flex justify-center f3 mt5",
                          },
                          [_vm._v("No data found")]
                        ),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "bg-adori-light-gray br3 ba b--adori-gray light-gray pv2 flex ph4 flex-column mt3",
                class: { "min-height": _vm.showLoader },
              },
              [
                _vm.showLoader
                  ? _c("PlaceHolder")
                  : _c(
                      "div",
                      {
                        staticClass: "w-100",
                        class: {
                          "min-height-1": _vm.ImpressionOverShow.length === 0,
                        },
                      },
                      [
                        _c("div", { staticClass: "f2 fw1 mt3" }, [
                          _vm._v("Impression by shows"),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ImpressionOverShow.length > 0,
                                expression: "ImpressionOverShow.length > 0",
                              },
                            ],
                            staticClass:
                              "flex justify-between items-center ph3",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex justify-center w-100" },
                              [
                                _c("PieChart", {
                                  attrs: { seriesData: _vm.ImpressionOverShow },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ImpressionOverShow.length === 0,
                                expression: "ImpressionOverShow.length === 0",
                              },
                            ],
                            staticClass: "flex justify-center f3 mt5",
                          },
                          [_vm._v("No data found")]
                        ),
                      ]
                    ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }