



































































































































































































import isEmpty from 'lodash.isempty'
import moment from 'moment'
import { Component, Vue, Watch } from 'vue-property-decorator'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import BarChart from '@/components/Common/Charts/BarChart.vue'
import PieChart from '@/components/Common/Charts/PieChart.vue'
import PlaceHolder from '@/components/Common/Charts/PlaceHolder.vue'
import { Action, Getter } from 'vuex-class'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    PieChart,
    BarChart,
    PlaceHolder,
  },
})
export default class ViewReport extends Vue {
  @Getter hasLoadedUser!: any
  // @Getter campaigns!: any
  @Getter analyticsByTime!: any
  @Getter analyticsByPosition!: any
  @Getter analyticsByFlight!: any
  @Getter analyticsByShow!: any
  @Getter analyticsSummary!: any
  @Getter analyticsReload!: any
  @Getter networkId!: string

  @Action getAnalyticsByTime!: any
  @Action getAnalyticsByPosition!: any
  @Action getAnalyticsByFlight!: any
  @Action getAnalyticsByShow!: any
  @Action getAnalyticsSummary!: any
  @Action setAnalyticsReload!: any
  @Action getOneCampaign!: any
  @Action exportCampaign!: any

  filter = 'DAILY'
  campaignId = ''
  campaign: any = {}
  loading = false
  timeLoader = false
  positionLoader = false
  flightLoader = false
  showLoader = false

  campaignStartDate: any = ''
  campaignEndDate: any = ''
  notBeforeStartDate: any = moment().utc().toISOString()

  notAfterStartDate: any = null
  notBeforeEndDate: any = moment().utc().toISOString()
  notAfterEndtDate: any = null

  created() {
    this.onLoadedUserChanged()
  }

  validateDate() {
    this.notBeforeEndDate = this.campaignStartDate

    this.notAfterStartDate = this.campaignEndDate

    if (['', null].includes(this.campaignEndDate)) {
      this.notAfterStartDate = this.campaign.endTime ? this.campaign.endTime : moment().startOf('day').utc()
    }

    if (['', null].includes(this.campaignStartDate)) {
      this.notBeforeEndDate = this.campaign.startTime
    }

    if (![this.campaignStartDate, this.campaignEndDate].includes(null)) {
      this.fetchAnalytics()
    }
  }

  async handleExportCampaign() {
    const payload = {
      networkId: this.networkId,
      campaignId: this.campaignId,
      params: {
        start_date: moment(this.campaignStartDate).startOf('day').utc().toISOString(),
        end_date: moment(this.campaignEndDate).endOf('day').utc().toISOString(),
      },
    }
    this.exportCampaign(payload)
  }

  async fetchAnalytics() {
    this.setAnalyticsReload({ value: true, campaignId: this.campaignId })
    this.loading = true
    this.timeLoader = true
    this.positionLoader = true
    this.flightLoader = true
    this.showLoader = true
    this.$store.commit('clearAnalytics')

    await this.getAnalyticsSummary({
      params: {
        start_date: this.campaignStartDate,
        end_date: this.campaignEndDate,
      },
      networkId: this.networkId,
      campaignId: this.campaignId,
    })

    this.loading = false

    await this.getAnalyticsByTime({
      params: {
        resolution: 'DAILY',
        start_date: this.campaignStartDate,
        end_date: this.campaignEndDate,
      },
      networkId: this.networkId,
      campaignId: this.campaignId,
    })

    await this.getAnalyticsByTime({
      params: {
        resolution: 'WEEKLY',
        start_date: this.campaignStartDate,
        end_date: this.campaignEndDate,
      },
      networkId: this.networkId,
      campaignId: this.campaignId,
    })

    await this.getAnalyticsByTime({
      params: {
        resolution: 'MONTHLY',
        start_date: this.campaignStartDate,
        end_date: this.campaignEndDate,
      },
      networkId: this.networkId,
      campaignId: this.campaignId,
    })
    this.timeLoader = false

    await this.getAnalyticsByPosition({
      params: {
        start_date: this.campaignStartDate,
        end_date: this.campaignEndDate,
      },
      networkId: this.networkId,
      campaignId: this.campaignId,
    })
    this.positionLoader = false

    await this.getAnalyticsByFlight({
      params: {
        start_date: this.campaignStartDate,
        end_date: this.campaignEndDate,
      },
      networkId: this.networkId,
      campaignId: this.campaignId,
    })
    this.flightLoader = false

    await this.getAnalyticsByShow({
      params: {
        start_date: this.campaignStartDate,
        end_date: this.campaignEndDate,
      },
      networkId: this.networkId,
      campaignId: this.campaignId,
    })
    this.showLoader = false
  }

  @Watch('hasLoadedUser')
  async onLoadedUserChanged() {
    this.loading = true
    this.timeLoader = true
    this.positionLoader = true
    this.flightLoader = true
    this.showLoader = true
    this.campaignId = this.$route.params.id
    if (this.hasLoadedUser) {
      if (this.$route.params.campaign) {
        this.campaign = this.$route.params.campaign
      } else {
        const campaign = await this.getOneCampaign({
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
        this.campaign = campaign
      }

      if (this.campaign.endTime) {
        this.campaignStartDate = this.notBeforeStartDate = this.notBeforeEndDate = this.campaign.startTime
        this.campaignEndDate = this.notAfterStartDate = this.notAfterEndtDate = this.campaign.endTime
      } else {
        this.campaignStartDate = this.notBeforeStartDate = this.notBeforeEndDate = this.campaign.startTime
        this.campaignEndDate = this.notAfterStartDate = this.notAfterEndtDate = moment().startOf('day').toISOString()
      }

      if (isEmpty(this.analyticsSummary[this.campaignId]) || this.analyticsReload[this.campaignId]) {
        await this.getAnalyticsSummary({
          params: {
            start_date: this.campaignStartDate,
            end_date: this.campaignEndDate,
          },
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
      }
      this.loading = false

      if (isEmpty(this.analyticsByTime[this.campaignId]) || this.analyticsReload[this.campaignId]) {
        await this.getAnalyticsByTime({
          params: {
            resolution: 'DAILY',
            start_date: this.campaignStartDate,
            end_date: this.campaignEndDate,
          },
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
        await this.getAnalyticsByTime({
          params: {
            resolution: 'WEEKLY',
            start_date: this.campaignStartDate,
            end_date: this.campaignEndDate,
          },
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
        await this.getAnalyticsByTime({
          params: {
            resolution: 'MONTHLY',
            start_date: this.campaignStartDate,
            end_date: this.campaignEndDate,
          },
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
      }
      this.timeLoader = false

      if (isEmpty(this.analyticsByPosition[this.campaignId]) || this.analyticsReload[this.campaignId]) {
        await this.getAnalyticsByPosition({
          params: {
            start_date: this.campaignStartDate,
            end_date: this.campaignEndDate,
          },
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
      }
      this.positionLoader = false

      if (isEmpty(this.analyticsByFlight[this.campaignId]) || this.analyticsReload[this.campaignId]) {
        await this.getAnalyticsByFlight({
          params: {
            start_date: this.campaignStartDate,
            end_date: this.campaignEndDate,
          },
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
      }
      this.flightLoader = false

      if (isEmpty(this.analyticsByShow[this.campaignId]) || this.analyticsReload[this.campaignId]) {
        await this.getAnalyticsByShow({
          params: {
            start_date: this.campaignStartDate,
            end_date: this.campaignEndDate,
          },
          networkId: this.networkId,
          campaignId: this.campaignId,
        })
      }
      this.showLoader = false
      this.setAnalyticsReload({ value: false, campaignId: this.campaignId })
    }
  }

  get summary() {
    return this.analyticsSummary[this.campaignId]
  }

  get percentageDelivered() {
    const summary: any = this.analyticsSummary[this.campaignId]
    return `${
      isNaN(Number(((summary.totalServed / summary.totalGoal) * 100).toFixed(3)))
        ? 0
        : ((summary.totalServed / summary.totalGoal) * 100).toFixed(3)
    }%`
  }

  get campaignPeriod() {
    const startDate = moment(this.campaignStartDate)
    const endDate = moment(this.campaignEndDate)

    return {
      period: `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`,
      duration: `${endDate.diff(startDate, 'days')} day(s)`,
    }
  }

  get ImpressionOverTime() {
    const xData: string[] = this.analyticsByTime[this.campaignId][this.filter].map((obj: any) => obj.label)
    const yData: number[] = this.analyticsByTime[this.campaignId][this.filter].map((obj: any) => obj.count)
    let xGap: number

    switch (this.filter) {
      case 'DAILY':
        xGap = this.analyticsByTime[this.campaignId][this.filter].length > 20 ? 60 : 30
        break
      case 'WEEKLY':
        xGap = this.analyticsByTime[this.campaignId][this.filter].length > 20 ? 100 : 30
        break
      case 'MONTHLY':
        xGap = this.analyticsByTime[this.campaignId][this.filter].length > 20 ? 50 : 30
        break
      default:
        xGap = 20
        break
    }

    return {
      xData,
      yData,
      filter: this.filter,
      xGap,
    }
  }

  get ImpressionOverPosition() {
    return this.analyticsByPosition[this.campaignId].map((obj: any) => {
      return {
        name: obj.label,
        value: obj.count,
      }
    })
  }

  get ImpressionOverFlight() {
    return this.analyticsByFlight[this.campaignId].map((obj: any) => {
      return {
        name: obj.label,
        value: obj.count,
      }
    })
  }

  get ImpressionOverShow() {
    return this.analyticsByShow[this.campaignId].map((obj: any) => {
      return { name: obj.label, value: obj.count }
    })
  }
  changeFilter(filter: string) {
    this.filter = filter
  }
}
